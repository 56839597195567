import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, offsetRelativeToAncestor } from 'Shared/Helper/Dom/Dom';
import { waitForEventEnd } from 'Shared/Helper/Function/WaitForEventEnd';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';
import { isMobile } from 'Shared/Helper/ViewPort/ViewPort';

const NAME = 'listing-detail-page';

const CLASSES = {
    'sidebar': renderClasses('page', 'sidebar'),
    'sidebarElements': renderClasses('page', 'sidebar-elements'),
};

const SELECTORS = {
    'sidebar': `.${CLASSES.sidebar}`,
    'sidebarElements': `.${CLASSES.sidebarElements}`,
};

class ListingDetailPage extends ComponentController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'startSideBarReposition',
            'endSideBarReposition',
            'repositionSidebar',
        ]);

        this.sidebarRepositionRaf = null;

        document.addEventListener('scroll', this.startSideBarReposition);
        document.addEventListener('scroll', waitForEventEnd(this.endSideBarReposition));
    }

    startSideBarReposition () {
        if (this.sidebarRepositionRaf !== null || isMobile()) {
            return;
        }

        this.sidebarRepositionRaf = requestAnimationFrame(this.repositionSidebar);
    }

    repositionSidebar () {
        const sidebar = findOne(SELECTORS.sidebar);
        const sidebarElements = findOne(SELECTORS.sidebarElements);
        const sidebarOffset = offsetRelativeToAncestor(sidebar);
        const scrollOffset = window.scrollY;
        const margin = 16;

        let position = null;
        let top = null;
        let bottom = null;

        if (scrollOffset >= sidebarOffset.top - margin) {
            position = 'fixed';
            top = `${margin}px`;

            if (scrollOffset + margin + sidebarElements.offsetHeight > sidebarOffset.top + sidebar.offsetHeight) {
                position = 'absolute';
                bottom = '0';
                top = null;
            }
        }

        sidebarElements.style.position = position;
        sidebarElements.style.top = top;
        sidebarElements.style.bottom = bottom;

        this.sidebarRepositionRaf = requestAnimationFrame(this.repositionSidebar);
    }

    endSideBarReposition () {
        if (this.sidebarRepositionRaf === null || isMobile()) {
            return;
        }

        cancelAnimationFrame(this.sidebarRepositionRaf);
        this.sidebarRepositionRaf = null;
    }

    onNotifyMeClick () {
        this.messageBus.postMessage({
            'message': 'requestPropertyAlertWizardDialog',
        });
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailPage,
};
